import Vue from "vue";

function getRef(orgId, caseId) {
  return Vue.prototype.$db
    .collection("organisations")
    .doc(orgId)
    .collection("cases")
    .doc(caseId)
    .collection("alerts");
}

export async function getById(orgId, caseId, id) {
  return getRef(orgId, caseId)
    .doc(id)
    .get();
}

export async function list(orgId, caseId) {
  return getRef(orgId, caseId).get();
}

export async function countAlerts(orgId, caseId) {
  const dt = new Date();
  return getRef(orgId, caseId)
    .where("expiryDate", ">=", dt)
    .get();
}
export async function save(orgId, caseId, payload) {
  const obj = Object.assign({}, payload);

  if (obj.id != undefined) {
    return getRef(orgId, caseId)
      .doc(obj.id)
      .update(obj);
  } else {
    return getRef(orgId, caseId).add(obj);
  }
}
